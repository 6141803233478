<template>
  <div class="survey-page">
    <div
      class="card"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <el-row :gutter="20">
        <el-col :span="20"
          ><div class="text-grey-dark title-md text-bold">
            {{ $t("survey_name") }}：{{ info.name }}
          </div>
          <div class="text-grey text-md mt-20">
            {{ $t("gold_num") }}：<span class="text-orange title-md">{{
              info.points
            }}</span>
            {{ $t("gold") }}
          </div>

          <div
            class="text-grey text-md mt-10"
            v-if="info.extraPoints && info.extraPoints > 0"
          >
            {{ $t("extra_gold_num") }}：<span class="text-orange title-md">{{
              info.extraPoints || 0
            }}</span>
            {{ $t("gold") }}
          </div>

          <div class="text-grey text-md mt-10">
            {{ $t("reward_mode") }}：<span class="text-orange title-md">{{
              info.realtime
            }}</span>
          </div>

          <div class="text-grey text-md mt-10">
            {{ $t("survey_time_about") }}
            <span class="text-orange title-md">{{ info.loi }}</span>
            {{ $t("minutes") }}
          </div>

          <div class="text-grey text-md mt-10">
            {{ $t("difficult_level") }}：
            <span class="text-orange title-md">{{ info.ir }}%</span>
          </div>

          <div class="text-grey text-md mt-10">
            {{ $t("survey_requirements") }}：<span
              v-html="info.describe || $t('none')"
            ></span>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button class="mt-20" type="primary" round @click="takeIn()">{{
            $t("begin_to_answer")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card">
      <div class="text-grey-dark text-min text-bold">
        {{ $t("attentions") }}
      </div>
      <div class="text-grey-dark text-min mt-5 text-bold">
        {{ $t("attentions_tip1") }}
      </div>
      <div class="text-grey-dark text-min mt-5 text-bold">
        {{ $t("attentions_tip2") }}
      </div>
      <div class="text-grey-dark text-min mt-5 text-bold">
        {{ $t("attentions_tip3") }}
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters } from "vuex";
//import { parseParams } from "../../../common/util";
export default {
  data() {
    return {
      info: {},
      loading: false
    };
  },
  async created() {
    this.loading = true;
    const res = await this.$http.get(
      `/api/v1/Surveys/${this.$route.params.id}`
    );
    if (res.status !== 200) {
      this.$message.error(res.statusText);
    }
    this.info = res.data;
    this.loading = false;
  },
  methods: {
    takeIn() {
      window.open(
        `https://www.sudiaoba.com/SubURL.aspx?webid=` +
          (this.info.webid || "sudiaoba") +
          `&pkg=` +
          this.info.package +
          `&uid=` +
          this.account.id
      );
    }
  },
  computed: {
    ...mapGetters(["account"])
  }
};
</script>
